import "styles/pages/recipies.scss"

import React, { useState } from "react"
import { graphql, Link } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"
import PageHeader from "components/PageHeader/PageHeader"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"

import icoAllar from "assets/images/ico-recipe-allar.svg"
import icoBaking from "assets/images/ico-recipe-baking.svg"
import icoBreakfast from "assets/images/ico-recipe-breakfast.svg"
import icoDessert from "assets/images/ico-recipe-dessert.svg"
import icoDinner from "assets/images/ico-recipe-dinner.svg"
import icoDressing from "assets/images/ico-recipe-dressing.svg"

const Recipies = ({ data }) => {
  const title = "Uppskriftir"

  const [query, setQuery] = useState("")
  const [categoryFilter, setCategoryFilter] = useState(null)
  const [productFilter, setProductFilter] = useState(null)

  return (
    <Layout>
      <Seo title={title} />
      <PageHeader title={title} />
      <Breadcrumbs title={title} />

      <section className="recipies-filters">
        <div className="container-fluid">
          <div className="row align-items-end">
            <div className="col-lg-5">
              <p>BÓLKAR</p>
              <div className="recipies-filters__categories">
                <button
                  className={categoryFilter === null ? "current" : ""}
                  onClick={() => setCategoryFilter(null)}
                  title="Allar"
                >
                  <img src={icoAllar} alt="" />
                </button>
                <button
                  className={categoryFilter === "baking" ? "current" : ""}
                  onClick={() => setCategoryFilter("baking")}
                  title="Baking"
                >
                  <img src={icoBaking} alt="" />
                </button>
                <button
                  className={categoryFilter === "breakfast" ? "current" : ""}
                  onClick={() => setCategoryFilter("breakfast")}
                  title="Morgummatur"
                >
                  <img src={icoBreakfast} alt="" />
                </button>
                <button
                  className={categoryFilter === "desert" ? "current" : ""}
                  onClick={() => setCategoryFilter("desert")}
                  title="Omaná"
                >
                  <img src={icoDessert} alt="" />
                </button>
                <button
                  className={categoryFilter === "dinner" ? "current" : ""}
                  onClick={() => setCategoryFilter("dinner")}
                  title="Døgverði"
                >
                  <img src={icoDinner} alt="" />
                </button>
                <button
                  className={categoryFilter === "dressing" ? "current" : ""}
                  onClick={() => setCategoryFilter("dressing")}
                  title="Dressingur"
                >
                  <img src={icoDressing} alt="" />
                </button>
              </div>
            </div>
            <div className="col-lg-3 offset-lg-1">
              <div className="select-wrapper">
                <select
                  id="department"
                  value={productFilter}
                  onChange={e => setProductFilter(e.target.value)}
                  defaultValue="none"
                >
                  <option value="none" disabled>
                    Uppskriftir
                  </option>
                  {data.allWpProduct.nodes.map((item, index) => (
                    <option value={item.id} key={index}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="input-wrapper">
                <input
                  type="text"
                  name="search"
                  placeholder="NAVN"
                  value={query}
                  onChange={({ target: { value } }) => setQuery(value)}
                  className="searchInput"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="recipies-content">
        <div className="container-fluid">
          <div className="row">
            {data.allWpRecipies.nodes
              .filter(item =>
                categoryFilter
                  ? item.acfRecipies.recipeCategory === categoryFilter
                  : item
              )
              .filter(
                item =>
                  item.title.includes(query) ||
                  item.title.toLowerCase().includes(query)
              )
              .filter(item => {
                const relatedProducts =
                  item.acfRecipies.recipeRelatedProducts &&
                  item.acfRecipies.recipeRelatedProducts.map(item => item.id)
                return productFilter
                  ? relatedProducts.includes(productFilter)
                  : item
              })
              .map((item, index) => (
                <div className="col-lg-4 col-sm-6" key={index}>
                  <Link to={`/${item.slug}`} className="recipies__tile">
                    <div
                      className="recipies__tile-image"
                      style={{
                        backgroundImage: `url('${item?.featuredImage?.node?.sourceUrl}')`,
                      }}
                    ></div>
                    <p className="recipies__tile-title">{item.title}</p>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpRecipies: allWpRecipies {
      nodes {
        id
        title
        slug
        featuredImage {
          node {
            sourceUrl
          }
        }
        acfRecipies {
          recipeCategory
          recipeRelatedProducts {
            ... on WpProduct {
              id
            }
          }
        }
      }
    }
    allWpProduct: allWpProduct(sort: { fields: title }) {
      nodes {
        id
        title
        slug
      }
    }
  }
`

export default Recipies
